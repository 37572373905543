import gql from 'graphql-tag'

export const CreatePromotion = gql`
  mutation createPromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      success
    }
  }
`

export const EditPromotion = gql`
  mutation editPromotion($input: EditPromotionInput!) {
    editPromotion(input: $input) {
      success
    }
  }
`

export const DeletePromotion = gql`
  mutation deletePromotion($input: DeletePromotionInput!) {
    deletePromotion(input: $input) {
      success
    }
  }
`
