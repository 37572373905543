<template>
  <div>
    <v-dialog
      v-model="modal"
      width="400"
      >
      <v-card>
        <v-card-title
          class="justify-center"
          >
          Eliminar promoción
        </v-card-title>

        <v-card-text>
          <div>
            ¿Está seguro de que desea eliminar la promoción {{ promotion.name }}? Esta acción <span class="error--text font-weight-bold">no se puede deshacer</span>.
          </div>
          <div
            class="text-right mt-3"
            >
            <v-btn
              text
              @click="modal = false"
              >
              Cancelar
            </v-btn>

            <v-btn
              text
              color="error"
              @click="deletePromotion"
              >
              Eliminar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card
      outlined
      class="mb-3"
      >
      <v-card-text>
        <v-row
          align="center"
          >
          <v-col
            >
            {{ promotion.name }}
          </v-col>
          <v-col
            >
            <div
              v-if="promotion.code"
              >
              Cód.: {{ promotion.code }}
            </div>
          </v-col>
          <v-col
            >
            Valor: {{ promotion.value }}{{ promotion.promotionType == 'fixed_value' ? '$' : '%' }}
          </v-col>
          <v-col
            class="text-right"
            >
            <v-btn
              icon
              color="error"
              @click="modal = true"
              small
              >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DeletePromotion } from '@/graphql/mutations/promotions'

export default {
  data: () => ({
    modal: false
  }),

  props: {
    promotion: {
      type: Object,
      required: true
    }
  },

  methods: {
    deletePromotion () {
      this.$apollo.mutate({
        mutation: DeletePromotion,
        variables: {
          input: {
            id: this.promotion.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.modal = false
      })
    }
  }
}
</script>
